.bgheaderimg{
    background: 
  url(../images/header\ img.jpg);
  background-size: cover;
  
}
.imgtext{
    font-family: 'Test Tiempos Headline';
    font-style: normal;
    font-size: 36px;
    color:aliceblue;
    margin-top: 1%;
    padding-top: 0.5%;
    padding-left: 2%;
    padding-bottom: 0.5%;
    
}

.section{
    padding-top: 1%;
    font-size: 20px;
}

.grid-container {
    position: relative;
    display: grid;
    grid-template-columns: 15px 15px 15px 15px 15px 15px 15px;
    gap: 4px;
    float:right;
    padding-top: 1%;
    /* padding-left: 1200px; */
  }
  
  .grid-container > div {
    border: 1px solid grey;
    
    text-align: center;
    padding: 6px 0;
    
  }

  .grid-container-body{
      display: grid;
      grid-template-columns: 40% 60%;
  }
  .grid-container-body > div  {
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    font-size: 15px;
  }

  .grid-container-header{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    padding: 2px;
  }
  .grid-container-header > div  {
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 10px;
    font-size: 15px;

  }
 .header{
   display: grid;
   grid-template-columns: 12% 12% 13% 12% 13% 12% 12% 14%;
   padding: 0px;
   /* padding-top: 10%; */

   
 }
 .header > div {
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 6px;
  font-size: 15px;
  /* padding-top: 10%; */
  text-align: center;
  /* vertical-align: text-bottom; */
  
 }
  .bgcolor{
    background-color: #F0F6FF;
    min-height: 100%;
    
  }
  .bgcolor2{
    background-color: #FAFCFF;
    min-height: 100%;
   
  }
  .bgError{
    background-color: #FFEECC;
    min-height: 100%;
    
  }

  .svbtn{
    display: flex;
    justify-content: center;
    color: whitesmoke;
    /* padding-bottom: 1rem; */
    align-items: center;
    margin-left: 48%;
    margin-right: 30%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #0054C9;
    border: none;
    /* padding: 9px 30px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    height: 2rem;
  
  }
  .errmsg{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background: #FFEECC;
    margin-left: 36%;
    margin-right: 30%;
    text-align: center;
  }

 .compltmsg{
     /* font-family: "Open Sans"; */
     font-weight: 800 bold;
 }

 .rsltbtn{
  display: flex;
  justify-content: center;
  color: whitesmoke;
  align-items: center;
  font-size: 16px;
  background-color: #0054C9;
  border: none;
  padding: 5px 10px;
  margin-bottom: 4%;
  margin-left: -7%;
  cursor: pointer;
 }


 .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0px;
  white-space: nowrap;
  /* height: 1px; */
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
  /* height: 1px; */
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
  /* height: 1px; */
}
.ant-steps-item-title:after {
  left: -49%;
  height: 1px;
}











/* .CustomButton .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: red;
}  */


.custom-tooltip{
  background-color: white;
  min-width: 7vw;
  /* min-height: 7vw; */
  border: 2px solid #06a3e9;
  text-align: center;
  line-height: 150%;
  /* padding: 40px; */
}



.grid-container-post-survey {
  position: relative;
  display: grid;
  grid-template-columns: 60px 150px ;
  /* background-color: #FFF1D6; */
  gap: 5px;
  float:right;
  padding-top: 0.5%;
  padding-right: 17%;
  /* padding-bottom: 20%; */
  width: 4vw;
  /* padding-bottom: 100%; */
  /* height: 0.5vw; */
  /* padding-left: 1200px; */
}

.grid-container-post-survey > div {
  /* border: 1px solid #FFB234; */
  text-align: center;
  font-size: 20px;
  height: 60%;
  /* font-family: Open sans-serif; */
  
}


.ant-steps-item-title:after {
  left: -49%;
  height: 0.5px;
}
