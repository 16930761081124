.bgimg{
background: 
  url(./images/landing_page_img.jpg);
  background-size: cover;
  height: 19rem;
}

.centered2{
  position: relative; 
  font-family: 'Open-Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: auto;
  text-align: center;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #A1CEFB;
  padding-top: 4rem;
  margin-top:1rem
  }

  .centered {
    color: aliceblue;
    font-family: 'Test Tiempos Headline';
    font-size: 64px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -1px;
    text-align: center;
    padding-top: 3rem;
  }

  .centered1 {
    color: aliceblue;
    height: 24px;
    text-align: center;
    font-family: 'Open-Sans';
    font-size: 30px;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    padding-top: 3rem;

  }

  .zsimg{
    margin-top: 1rem;
    margin-left: 2.5rem;
  }

  .Passage_Text1{
    
    position: relative;
    padding-left: 15%;
    padding-right: 15%;
    font-family: Open-Sans;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
  .P_head_color{
    color: #0E2C88;

    line-height: 10%;
    padding-top: 1%;
    font-family: "Open-Sans";
    font-weight: 700 bold;
  }
  .Passage1{
    line-height: 10%;
  }
  .Get_Start_btn a{
    color: white;
  }
  .Get_Start_btn{
   
    font-family: Arial, Helvetica, sans-serif;
    background-color: #0054C9;
    border: none;
    padding: 8px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }

  .footer{
    position: relative;
    padding-top: 2%;
    padding-left: 3%;
  }


 
